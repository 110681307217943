<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Carga Individual</h2>
        </div>
        <div class="columns is-justify-content-center">
            <div class="column is-two-thirds-desktop">
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">Información de Usuario</p>
                    </div>
                    <div class="card-content">
                        <div class="columns">
                            <div class="column">
                                <!-- <b-field label="Pais *" label-position="on-border" :type="existeTrabajadorResponse.existe !== null ? (existeTrabajadorResponse.existe ? 'is-danger' : 'is-success') : null">
                                    <b-autocomplete
                                        v-model="form.persona.pais"
                                        :loading="loading.paises"
                                        placeholder="Seleccione País"
                                        :open-on-focus="true"
                                        :data="filteredDataObj"
                                        field="pais"
                                        @select="option => (selected = option)"
                                        :clearable="true"
                                        @input="existeTrabajador()"
                                    >
                                    </b-autocomplete>
                                </b-field> -->
                                <autocomplete-paises
                                    v-model="form.persona.pais_id"
                                />
                            </div>
                            <div class="column">
                                <b-field
                                    label="DNI *"
                                    label-position="on-border"
                                    :type="
                                        existeTrabajadorResponse.existe !== null
                                            ? existeTrabajadorResponse.existe
                                                ? 'is-danger'
                                                : 'is-success'
                                            : null
                                    "
                                    :message="
                                        existeTrabajadorResponse.existe
                                            ? existeTrabajadorResponse.message
                                            : ''
                                    "
                                >
                                    <b-input
                                        v-model="form.persona.dni"
                                        v-cleave="masks.dni"
                                        name="dni"
                                        placeholder="N° Documento de Indentidad"
                                        @input="existeTrabajador()"
                                        :loading="loading.existeTrabajador"
                                        lazy
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field
                                    label="Nombre *"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="form.trabajador.nombre"
                                        name="nombre"
                                        @input="
                                            (value) =>
                                                (form.trabajador.nombre =
                                                    value.toUpperCase())
                                        "
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field
                                    label="Apellido Paterno *"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="
                                            form.trabajador.apellido_paterno
                                        "
                                        name="apellido_paterno"
                                        @input="
                                            (value) =>
                                                (form.trabajador.apellido_paterno =
                                                    value.toUpperCase())
                                        "
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field
                                    label="Apellido Materno *"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="
                                            form.trabajador.apellido_materno
                                        "
                                        name="apellido_materno"
                                        @input="
                                            (value) =>
                                                (form.trabajador.apellido_materno =
                                                    value.toUpperCase())
                                        "
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">Credenciales</p>
                    </div>
                    <div class="card-content">
                        <div class="columns">
                            <div class="column">
                                <b-field
                                    label="Nombre de Usuario *"
                                    label-position="on-border"
                                    :type="
                                        usernameAvailabilityResponse.availability !==
                                        null
                                            ? usernameAvailabilityResponse.availability
                                                ? 'is-success'
                                                : 'is-danger'
                                            : null
                                    "
                                    :message="
                                        !usernameAvailabilityResponse.availability
                                            ? usernameAvailabilityResponse.message
                                            : ''
                                    "
                                >
                                    <b-input
                                        v-model="form.usuario.username"
                                        name="username"
                                        @input="verifyUsernameAvailability()"
                                        disabled
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field
                                    label="Email"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="form.usuario.email"
                                        name="email"
                                        placeholder="(opcional)"
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field
                                    label="Contraseña *"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="form.usuario.password"
                                        name="password"
                                        type="password"
                                        password-reveal
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field
                                    label="Confirmar Contraseña *"
                                    label-position="on-border"
                                >
                                    <b-input
                                        v-model="
                                            form.usuario.password_confirmation
                                        "
                                        name="password_confirmation"
                                        type="password"
                                        password-reveal
                                        required
                                    ></b-input>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">Configuración</p>
                    </div>
                    <div class="card-content">
                        Los usuarios creados tendrán
                        <b class="has-text-primary"
                            >por defecto el perfil empleado</b
                        >, para asignarle perfiles de administrador deberá
                        ingresar al detalle del empleado una vez creado.
                    </div>
                </div>
                <br />
                <b-button
                    type="is-success"
                    @click="handleCargaIndividual"
                    :loading="this.loading.submit"
                    expanded
                >
                    Guardar
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import Message from '@/utils/Message';
import AutocompletePaises from '../../../../components/shared/FormControls/AutocompletePaises.vue';

const initalState = {
    persona: {
        dni: '',
        pais_id: '',
    },
    trabajador: {
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        fecha_nacimiento: '',
    },
    usuario: {
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
    },
};

export default {
    components: {
        AutocompletePaises,
    },
    data() {
        return {
            loading: {
                submit: false,
                existeTrabajador: false,
                username: false,
            },
            form: initalState,
            masks: {
                dni: {
                    blocks: [15],
                    numeralOnly: true,
                },
            },
        };
    },
    computed: {
        existeTrabajadorResponse() {
            return this.$store.state.trabajadores.existeTrabajadorResponse;
        },
        usernameAvailabilityResponse() {
            return this.$store.state.usuarios.usernameAvailabilityResponse;
        },
    },
    watch: {
        //
    },
    async mounted() {},
    methods: {
        clearForm() {
            this.form.persona.dni = '';
            this.form.trabajador.nombre = '';
            this.form.trabajador.apellido_paterno = '';
            this.form.trabajador.apellido_materno = '';
            this.form.trabajador.fecha_nacimiento = '';
            this.form.usuario.username = '';
            this.form.usuario.email = '';
            this.form.usuario.password = '';
            this.form.usuario.password_confirmation = '';
        },
        async existeTrabajador() {
            if (
                this.form.persona.dni !== '' &&
                this.form.persona.pais_id !== ''
            ) {
                this.loading.existeTrabajador = true;
                try {
                    const { existe } = await this.$store.dispatch(
                        'trabajadores/existe',
                        this.form.persona
                    );
                    if (!existe) {
                        this.form.usuario.username = this.form.persona.dni;
                    }
                } catch (err) {
                    //console.log(err);
                } finally {
                    this.loading.existeTrabajador = false;
                }
            }
        },
        async verifyUsernameAvailability() {
            this.loading.username = true;
            await this.$store.dispatch(
                'usuarios/getUsernameAvailability',
                this.form.usuario.username
            );
            this.loading.username = false;
        },
        async handleCargaIndividual() {
            this.loading.submit = true;
            try {
                const res = await this.$store.dispatch('trabajadores/create', {
                    ...this.form,
                });
                this.clearForm();
                Message.showSuccessMessage(this, res.message);
            } catch (err) {
                Message.showErrorMessage(this, err.message, err.errors);
            } finally {
                this.loading.submit = false;
            }
        },
    },
};
</script>

<style></style>
