<template>
    <b-field label="Pais" label-position="on-border">
        <b-autocomplete
            icon="magnify"
            placeholder="Buscar por nombre"
            field="descripcion"
            :clearable="true"
            :open-on-focus="true"
            autocomplete="no"
            :loading="isLoading"
            :data="paises"
            @select="selectOption"
            :required="required"
        >
            <template slot-scope="props">
                <div class="is-flex is-align-content-center">
                    <img
                        class="image is-16x16"
                        :src="props.option.ruta_imagen"
                        :alt="props.option.descripcion"
                    />
                    <span class="ml-1">{{ props.option.descripcion }}</span>
                </div>
            </template>
            <template #empty>No se encontraron resultados</template>
        </b-autocomplete>
    </b-field>
</template>

<script>
export default {
    props: {
        required: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: 'paisId',
        event: 'paisIdChange'
    },
    data() {
        return {
            isLoading: false,
            selected: null,
        };
    },
    computed: {
        paisIdLocal: {
            get: function() {
                return this.paisId;
            },
            set: function(value) {
                this.$emit('paisIdChange', value);
            }
        },
        paises() {
            return this.$store.state.paises.data;
        }
    },
    async mounted() {
        if (this.paises.length === 0) {
            await this.fetchPaises();
        }
    },
    methods: {
        async fetchPaises() {
            this.isLoading = true;
            await this.$store.dispatch('paises/get');
            this.isLoading = false;
        },
        selectOption(option) {
            this.selected = option;
            this.paisIdLocal = option?.id || '';
        }
    }
};
</script>
