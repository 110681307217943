var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"columns is-justify-content-center"},[_c('div',{staticClass:"column is-two-thirds-desktop"},[_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('autocomplete-paises',{model:{value:(_vm.form.persona.pais_id),callback:function ($$v) {_vm.$set(_vm.form.persona, "pais_id", $$v)},expression:"form.persona.pais_id"}})],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"DNI *","label-position":"on-border","type":_vm.existeTrabajadorResponse.existe !== null
                                        ? _vm.existeTrabajadorResponse.existe
                                            ? 'is-danger'
                                            : 'is-success'
                                        : null,"message":_vm.existeTrabajadorResponse.existe
                                        ? _vm.existeTrabajadorResponse.message
                                        : ''}},[_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.masks.dni),expression:"masks.dni"}],attrs:{"name":"dni","placeholder":"N° Documento de Indentidad","loading":_vm.loading.existeTrabajador,"lazy":"","required":""},on:{"input":function($event){return _vm.existeTrabajador()}},model:{value:(_vm.form.persona.dni),callback:function ($$v) {_vm.$set(_vm.form.persona, "dni", $$v)},expression:"form.persona.dni"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre *","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"nombre","required":""},on:{"input":function (value) { return (_vm.form.trabajador.nombre =
                                                value.toUpperCase()); }},model:{value:(_vm.form.trabajador.nombre),callback:function ($$v) {_vm.$set(_vm.form.trabajador, "nombre", $$v)},expression:"form.trabajador.nombre"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Apellido Paterno *","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"apellido_paterno","required":""},on:{"input":function (value) { return (_vm.form.trabajador.apellido_paterno =
                                                value.toUpperCase()); }},model:{value:(
                                        _vm.form.trabajador.apellido_paterno
                                    ),callback:function ($$v) {_vm.$set(_vm.form.trabajador, "apellido_paterno", $$v)},expression:"\n                                        form.trabajador.apellido_paterno\n                                    "}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Apellido Materno *","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"apellido_materno","required":""},on:{"input":function (value) { return (_vm.form.trabajador.apellido_materno =
                                                value.toUpperCase()); }},model:{value:(
                                        _vm.form.trabajador.apellido_materno
                                    ),callback:function ($$v) {_vm.$set(_vm.form.trabajador, "apellido_materno", $$v)},expression:"\n                                        form.trabajador.apellido_materno\n                                    "}})],1)],1)])])]),_c('br'),_c('div',{staticClass:"card"},[_vm._m(2),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Nombre de Usuario *","label-position":"on-border","type":_vm.usernameAvailabilityResponse.availability !==
                                    null
                                        ? _vm.usernameAvailabilityResponse.availability
                                            ? 'is-success'
                                            : 'is-danger'
                                        : null,"message":!_vm.usernameAvailabilityResponse.availability
                                        ? _vm.usernameAvailabilityResponse.message
                                        : ''}},[_c('b-input',{attrs:{"name":"username","disabled":"","required":""},on:{"input":function($event){return _vm.verifyUsernameAvailability()}},model:{value:(_vm.form.usuario.username),callback:function ($$v) {_vm.$set(_vm.form.usuario, "username", $$v)},expression:"form.usuario.username"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Email","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"email","placeholder":"(opcional)"},model:{value:(_vm.form.usuario.email),callback:function ($$v) {_vm.$set(_vm.form.usuario, "email", $$v)},expression:"form.usuario.email"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Contraseña *","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"password","type":"password","password-reveal":"","required":""},model:{value:(_vm.form.usuario.password),callback:function ($$v) {_vm.$set(_vm.form.usuario, "password", $$v)},expression:"form.usuario.password"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Confirmar Contraseña *","label-position":"on-border"}},[_c('b-input',{attrs:{"name":"password_confirmation","type":"password","password-reveal":"","required":""},model:{value:(
                                        _vm.form.usuario.password_confirmation
                                    ),callback:function ($$v) {_vm.$set(_vm.form.usuario, "password_confirmation", $$v)},expression:"\n                                        form.usuario.password_confirmation\n                                    "}})],1)],1)])])]),_c('br'),_vm._m(3),_c('br'),_c('b-button',{attrs:{"type":"is-success","loading":this.loading.submit,"expanded":""},on:{"click":_vm.handleCargaIndividual}},[_vm._v(" Guardar ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('h2',{staticClass:"has-text-primary-dark"},[_vm._v("Carga Individual")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Información de Usuario")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Credenciales")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Configuración")])]),_c('div',{staticClass:"card-content"},[_vm._v(" Los usuarios creados tendrán "),_c('b',{staticClass:"has-text-primary"},[_vm._v("por defecto el perfil empleado")]),_vm._v(", para asignarle perfiles de administrador deberá ingresar al detalle del empleado una vez creado. ")])])}]

export { render, staticRenderFns }